import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import Map from '../components/map';
import SEO from '../components/seo';
import { FaFacebookSquare } from 'react-icons/fa'
import { MdLocationPin, MdPhone } from 'react-icons/md'

const location = {
  address: '333 Washington Blvd, Abilene TX',
  center: {
    // lat: 32.450798527516916,
    lat: 32.450798527516916,
    lng: -99.71029371407073,
  },
  centerrr: {
    // lat: 32.450798527516916,
    lat: 32.458802,
    lng: -99.71029371407073,
  },
  zoom: 15
}
// 32.450798527516916, -99.71029371407073

export default function ContactPage() {
  // const [width, setWidth] = useState(window.innerWidth)
  const [width, setWidth] = useState(0)

  useEffect(() => {
    setWidth(window.innerWidth)
  }, [])
  
  function handleWindowSizeChange() {
    setWidth(window.innerWidth)
  }

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange)
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange)
    }
  }, [])

  const isMobile = width <= 768;

  return(
    <div className="contact-container">
      <SEO title="Contact" />
      {/* <h2>Contact Us</h2> */}
      {/* <p>I will hold a form, phone number, address, emails, map etc</p> */}
      <div className="left-column">
        <Map location={location} zoomLevel={isMobile ? 14 : 15} />
      </div>
      <div className="right-column">
        <h2>Contact Us</h2>

        <a className="map-link" href="http://maps.google.com/?q=333 Washington Blvd, Abilene, TX, 79601" target="_blank">
        <div className="contact-address">
          <MdLocationPin />
          <p id="office-address">Office Address</p>
          <p>
            333 Washington Blvd
            <br />
            Abilene, TX 79601
          </p>
        </div>
        </a>

        <div className="contact-service-requests">
          <span><MdPhone /></span>
          
          <p>For service requests, call</p>
          <button className="phone-cta"><a href="tel:3256723200">(325) 672-3200</a></button>
        </div>

        {/* TODO link to actual facebook */}
        <div className="contact-social">
          <span>
            <a href="https://www.facebook.com/people/Taylor-Hill-Apartments/100089210719631/" target="_blank">
              <FaFacebookSquare className="social-icon"/>  
            </a>
          </span>
          <p>Follow us on Facebook!</p>
        </div>
      </div>
    </div>
  )
}