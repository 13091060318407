import React from 'react'
import GoogleMapReact from 'google-map-react'
import { MdLocationPin } from 'react-icons/md'

const LocationPin = ({ text }) => (
  <div className="pin">
    <MdLocationPin className="pin-icon" />
    <p className="pin-text">{text}</p>
  </div>
)

const Map = ({ location, zoomLevel }) => (
  <div className="map">
    <div className="google-map">
      <GoogleMapReact
        bootstrapURLKeys={{ key: 'AIzaSyC0nh9_91f4UWh0ezh6vffxSqA9HGdyinU' }}
        defaultCenter={location.centerrr}
        defaultZoom={zoomLevel}
        yesIWantToUseGoogleMapApiInternals
      >
        <LocationPin
          lat={location.center.lat}
          lng={location.center.lng}
          text={"Our Office"}
        />
      </GoogleMapReact>
    </div>
  </div>
)

export default Map;